import { styled } from '@/stitches.config';

export const Text = styled('span', {
  position: 'relative',
  display: 'block',
  color: '$gray900',
  fontFamily: '$regular',
  fontStyle: 'normal',
  fontWeight: 'normal',
  m: '0',
  '& small': {
    fontSize: '80%',
  },
  '& strong, & b': {
    fontFamily: '$bold',
    fontStyle: 'normal',
  },
  '& p': {
    m: 0,
  },
  '& ul, & ol': {
    listStyle: 'none',
    m: 0,
    p: 0,
  },
  '& ol': {
    display: 'table',
    counterReset: 'listitem',
    '& > li': {
      '&:not(:only-child)': {
        display: 'table-row',
        '&::before': {
          content: 'counters(listitem, ".") ". "',
          display: 'table-cell',
          pr: '$1',
          counterIncrement: 'listitem',
          textAlign: 'right',
          whiteSpace: 'nowrap',
        },
      },
    },
  },
  '& ul': {
    display: 'grid',
    rowGap: '$1',
    '& > li': {
      position: 'relative',
      display: 'block',
      pl: '$4',
      '&::before': {
        content: '',
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
        width: 3,
        height: 3,
        bc: '$primary',
        ml: '-$4',
        mr: 13,
        left: '$1',
      },
      '& ol, & ul': {
        py: '$1',
      },
    },
  },
  '& a': {
    color: '$primary',
    textDecoration: 'underline 1px',
    'text-underline-position': 'from-font',
    transitionProperty: 'color',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease',
    '@hover': {
      '&:hover': {
        color: '$primary100',
      },
    },
  },
  variants: {
    font: {
      base: {
        fontFamily: '$regular',
      },
      bold: {
        fontFamily: '$bold',
      },
      display: {
        fontFamily: '$eurothai',
      },
    },
    size: {
      'text-display': {
        fontFamily: '$eurothai',
        fontSize: `clamp(140px, ${-360 * ((240 - 140) / 920) + 140}px + ${
          ((240 - 140) / 920) * 100
        }vw, 240px)`,
        lineHeight: '90%',
        textTransform: 'uppercase',
      },
      // display1my24 90%
      'text-h1': {
        fontFamily: '$eurothai',
        fontSize: `clamp(44px, ${-360 * ((64 - 44) / 920) + 44}px + ${
          ((64 - 44) / 920) * 100
        }vw, 64px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      // display2my24
      'text-h2': {
        fontFamily: '$eurothai',
        fontSize: `clamp(32px, ${-360 * ((48 - 32) / 920) + 32}px + ${
          ((48 - 32) / 920) * 100
        }vw, 48px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      // h3 100%
      'text-h3': {
        fontFamily: '$eurothai',
        fontSize: `clamp(24px, ${-360 * ((36 - 24) / 920) + 24}px + ${
          ((36 - 24) / 920) * 100
        }vw, 36px)`,
        lineHeight: '125%',
        textTransform: 'uppercase',
      },
      // h4 140%
      'text-sub-h1': {
        fontSize: `clamp(20px, ${-360 * ((24 - 20) / 920) + 20}px + ${
          ((24 - 20) / 920) * 100
        }vw, 24px)`,
        lineHeight: '125%',
      },
      // h5 150%
      'text-sub-h2': {
        fontSize: `clamp(18px, ${-360 * ((20 - 18) / 920) + 18}px + ${
          ((20 - 18) / 920) * 100
        }vw, 20px)`,
        lineHeight: '125%',
      },
      // h6
      'text-body-1': {
        fontSize: `clamp(16px, ${-360 * ((18 - 16) / 920) + 16}px + ${
          ((18 - 16) / 920) * 100
        }vw, 18px)`,
        lineHeight: '150%',
      },
      'text-body-2': {
        fontSize: `clamp(14px, ${-360 * ((16 - 14) / 920) + 14}px + ${
          ((16 - 14) / 920) * 100
        }vw, 16px)`,
        lineHeight: '150%',
      },
      // 3xs
      'text-caption': {
        fontSize: 12,
        lineHeight: '150%',
      },
      'text-button-1': {
        fontSize: 16,
        lineHeight: '112.5%',
      },
      'text-button-2': {
        fontSize: 14,
        lineHeight: '112.5%',
      },
      '3xs': {
        fontSize: 12,
        lineHeight: '150%',
      },
      '2xs': {
        fontSize: 13,
        lineHeight: '150%',
      },
      xs: {
        fontSize: 14,
        lineHeight: '150%',
      },
      sm: {
        fontSize: 15,
        lineHeight: '150%',
      },
      base: {
        fontSize: 16,
        lineHeight: '150%',
      },
      lg: {
        fontSize: 18,
        lineHeight: '150%',
      },
      xl: {
        fontSize: 20,
        lineHeight: '150%',
      },
      h6: {
        fontSize: `clamp(16px, ${-360 * ((18 - 16) / 920) + 16}px + ${
          ((18 - 16) / 920) * 100
        }vw, 18px)`,
        lineHeight: '150%',
      },
      h5: {
        fontSize: `clamp(18px, ${-360 * ((20 - 18) / 920) + 18}px + ${
          ((20 - 18) / 920) * 100
        }vw, 20px)`,
        lineHeight: '150%',
      },
      h4: {
        fontSize: `clamp(20px, ${-360 * ((24 - 20) / 920) + 20}px + ${
          ((24 - 20) / 920) * 100
        }vw, 24px)`,
        lineHeight: '140%',
      },
      display6My24: {
        fontFamily: '$eurothai',
        fontSize: `clamp(18px, ${-360 * ((24 - 18) / 920) + 18}px + ${
          ((24 - 18) / 920) * 100
        }vw, 24px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      display5My24: {
        fontFamily: '$eurothai',
        fontSize: `clamp(24px, ${-360 * ((28 - 24) / 920) + 24}px + ${
          ((28 - 24) / 920) * 100
        }vw, 28px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      display3: {
        fontSize: `clamp(24px, ${-360 * ((32 - 24) / 920) + 24}px + ${
          ((32 - 24) / 920) * 100
        }vw, 32px)`,
        lineHeight: '140%',
      },
      h3: {
        fontFamily: '$eurothai',
        fontSize: `clamp(24px, ${-360 * ((36 - 24) / 920) + 24}px + ${
          ((36 - 24) / 920) * 100
        }vw, 36px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      display3my24: {
        fontFamily: '$eurothai',
        fontSize: `clamp(28px, ${-360 * ((40 - 28) / 920) + 28}px + ${
          ((40 - 28) / 920) * 100
        }vw, 40px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      h2: {
        fontFamily: '$eurothai',
        fontSize: `clamp(36px, ${-360 * ((40 - 36) / 920) + 36}px + ${
          ((40 - 36) / 920) * 100
        }vw, 40px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      display2my24: {
        fontFamily: '$eurothai',
        fontSize: `clamp(32px, ${-360 * ((48 - 32) / 920) + 32}px + ${
          ((48 - 32) / 920) * 100
        }vw, 48px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      h1: {
        fontFamily: '$eurothai',
        fontSize: `clamp(40px, ${-360 * ((48 - 40) / 920) + 40}px + ${
          ((48 - 40) / 920) * 100
        }vw, 48px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      display1: {
        fontFamily: '$eurothai',
        fontSize: `clamp(40px, ${-360 * ((64 - 40) / 920) + 40}px + ${
          ((64 - 40) / 920) * 100
        }vw, 64px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      display1my24: {
        fontFamily: '$eurothai',
        fontSize: `clamp(44px, ${-360 * ((64 - 44) / 920) + 44}px + ${
          ((64 - 44) / 920) * 100
        }vw, 64px)`,
        lineHeight: '90%',
        textTransform: 'uppercase',
        '& span': {
          display: 'block',
          fontSize: `clamp(24px, ${-360 * ((32 - 24) / 920) + 24}px + ${
            ((32 - 24) / 920) * 100
          }vw, 32px)`,
          lineHeight: '90%',
        },
      },
    },
    variant: {
      'text-current': {
        color: '$current',
      },
      'text-black': {
        color: '$black',
      },
      'text-gray-darkest': {
        color: '$gray900',
      },
      'text-gray-dark': {
        color: '$gray800',
      },
      'text-gray': {
        color: '$gray700',
      },
      'text-gray-light': {
        color: '$gray600',
      },
      'text-gray-lightest': {
        color: '$gray200',
      },
      'text-white': {
        color: '$white',
      },
      'text-red': {
        color: '$primary',
      },
      'text-red-dark': {
        color: '$primary100',
      },
      'text-blue': {
        color: '$blue',
      },
      'text-blue-dark': {
        color: '$blue-dark',
      },
      'text-green': {
        color: '$green',
      },
      'text-silver': {
        color: '$silver',
      },
      'text-bronze': {
        color: '$bronze',
      },
      'text-gold': {
        color: '$gold',
      },
      'text-gold-medium': {
        color: '$gold-medium',
      },
    },
    theme: {
      light: {
        '& span': {
          color: '$gray900',
        },
      },
      dark: {
        '& span': {
          color: '$gray500',
        },
      },
    },
    wrap: {
      true: {
        '& span': {
          display: 'inline-block',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
          '-webkit-hyphens': 'auto',
          '-moz-hyphens': 'auto',
          hyphens: 'auto',
        },
      },
    },
    'sr-only': {
      true: {
        position: 'absolute',
        width: '1px',
        height: '1px',
        padding: 0,
        margin: '-1px',
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        whiteSpace: 'nowrap',
        borderWidth: '0',
      },
    },
    line: {
      true: {
        mb: '24px',
        '&::after': {
          content: '""',
          size: '32px',
          display: 'block',
          bc: '$primary',
          mt: '16px',
        },
      },
    },
    lines: {
      1: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      2: {
        whiteSpace: 'initial',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
      },
      3: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
      },
      4: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
      },
      5: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': '5',
        '-webkit-box-orient': 'vertical',
      },
    },
    textTransform: {
      uppercase: {
        textTransform: 'uppercase',
      },
    },
    lineThrough: {
      true: {
        textDecoration: 'line-through',
      },
    },
    underline: {
      true: {
        textDecoration: 'underline 1px',
        'text-underline-position': 'from-font',
      },
    },
    transition: {
      true: {
        transitionProperty: 'color',
        transitionDelay: 'var(--transition-delay)',
        transitionDuration: 'var(--transition-duration)',
        transitionTimingFunction: 'var(--transition-easing)',
      },
    },
    'bullet-same-color': {
      true: {
        '& ul': {
          '& > li': {
            '&::before': { bc: 'CurrentColor' },
          },
        },
      },
    },
    'has-primary-remark': {
      true: {
        '& ul': {
          '& > li:last-child': {
            color: '$primary',
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      line: 'true',
      size: 'h2',
      css: {
        mb: '24px',
        '&::after': {
          mt: '24px',
        },
      },
    },
    {
      variant: 'text-red',
      theme: 'light',
      size: 'display1my24',
      css: {
        '& span': {
          color: '$gray900',
        },
      },
    },
    {
      variant: 'text-red',
      theme: 'dark',
      size: 'display1my24',
      css: {
        '& span': {
          color: '$white',
        },
      },
    },
  ],
  defaultVariants: {
    size: 'base',
    transition: 'true',
  },
});
